/* Complement Materialize */

.text-justify {
  text-align: justify;
}

/* Default page setup */

@import "elements/nav";

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.container {
  clear: both;
  width: 90%;
}

@import "elements/footer";

/* Custom page rules */

@import 'components/color-variables';
@import 'components/variables';

.slider {
  margin: 10px auto auto;
}

.home-offset {
  margin-top: 5vh;
}

.title-offset {
  margin-top: 50vh;
}

.halfscreen {
  min-height: 50vh;
}

.fonted-title {
  color: rgb(75, 75, 75);
  font-family: 'Satisfy', cursive;
}

.side-by-side {
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
  }

  @media #{$medium-and-up} {
    .row:nth-child(even) :first-child {
      order: 1;
    }
  }
}

.collection-link-color {
  color: $collection-link-color;
}

@media #{$medium-and-up} {
  #gite-menu {
    position: fixed;
    top: $navbar-height;
    overflow: auto;
  }
}

.slider.fullscreen {
  position: relative;
}

iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

#map {
  margin-top: .5rem;
  margin-bottom: .5rem;

  min-height: 60vh;
}

#gites-cards {
  display: flex;
  flex-flow: row wrap;

  .col {
    float: none;
    display: flex;
  }

  .card {
    display: flex;
    flex-direction: column;
  }

  .card-content {
    flex-grow: 1;
  }

  .card-title {
    color: rgb(75, 75, 75);
    font-family: 'Satisfy', cursive;
  }
}

#home-title {
  .card-title {
    color: rgb(75, 75, 75);
    font-family: 'Satisfy', cursive;
    font-size: 36px;
  }
}

[id^=widget-produit-OSMB-62467] {
  margin-top: 1rem;

  select {
    display: inline-block;
  }
}

h2 {
 font-size: 1rem;
 font-weight: bold;
 line-height: 1.5;
}

h1 {
 font-size: 1.5rem;
 font-weight: bold;
 color: #565656;
 }

p {
 margin-left: 2rem;
}

p.no-margin {
  margin-left: 0;
}

.card .card-content p {
  margin-bottom: 1rem;
}

main ul {
 margin-left: 4rem;
}

main ul:not(.browser-default) li {
 list-style-type: circle;
}

main ul.collapsible li {
 list-style-type: none;
}

.collapsible-body {
 background: #fff;
}

.modal.modal-fixed-footer {
 height: 80%;
 max-height: 80%;
}

.flag-icon {
 margin-right: 3px;
}

.BlocPage select {
 display: block;
}
