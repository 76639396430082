footer.page-footer {
  padding-top: 0;

  a {
    margin-top: 5px;
  }

  i {
    font-size: 2rem;
  }

  .mdi-facebook-box:hover {
    color: #3B5998;
  }

  .mdi-google-plus-box:hover {
    color: #DC4E41;
  }

  .mdi-twitter-box:hover {
    color: #55ACEE;
  }
}