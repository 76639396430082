/* Complement Materialize */
.text-justify {
  text-align: justify; }

/* Default page setup */
nav {
  margin-bottom: 20px; }

.brand-logo {
  margin-left: 1rem;
  font-family: 'Satisfy', cursive; }

.lang-dropdown i {
  margin-left: 0;
  margin-right: 0; }

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

main {
  flex: 1 0 auto; }

.container {
  clear: both;
  width: 90%; }

footer.page-footer {
  padding-top: 0; }
  footer.page-footer a {
    margin-top: 5px; }
  footer.page-footer i {
    font-size: 2rem; }
  footer.page-footer .mdi-facebook-box:hover {
    color: #3B5998; }
  footer.page-footer .mdi-google-plus-box:hover {
    color: #DC4E41; }
  footer.page-footer .mdi-twitter-box:hover {
    color: #55ACEE; }

/* Custom page rules */
.slider {
  margin: 10px auto auto; }

.home-offset {
  margin-top: 5vh; }

.title-offset {
  margin-top: 50vh; }

.halfscreen {
  min-height: 50vh; }

.fonted-title {
  color: #4b4b4b;
  font-family: 'Satisfy', cursive; }

.side-by-side .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center; }

@media only screen and (min-width: 601px) {
  .side-by-side .row:nth-child(even) :first-child {
    order: 1; } }

.collection-link-color {
  color: #26a69a; }

@media only screen and (min-width: 601px) {
  #gite-menu {
    position: fixed;
    top: 64px;
    overflow: auto; } }

.slider.fullscreen {
  position: relative; }

iframe {
  border: 0;
  width: 100%;
  height: 100%; }

#map {
  margin-top: .5rem;
  margin-bottom: .5rem;
  min-height: 60vh; }

#gites-cards {
  display: flex;
  flex-flow: row wrap; }
  #gites-cards .col {
    float: none;
    display: flex; }
  #gites-cards .card {
    display: flex;
    flex-direction: column; }
  #gites-cards .card-content {
    flex-grow: 1; }
  #gites-cards .card-title {
    color: #4b4b4b;
    font-family: 'Satisfy', cursive; }

#home-title .card-title {
  color: #4b4b4b;
  font-family: 'Satisfy', cursive;
  font-size: 36px; }

[id^=widget-produit-OSMB-62467] {
  margin-top: 1rem; }
  [id^=widget-produit-OSMB-62467] select {
    display: inline-block; }

h2 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5; }

h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #565656; }

p {
  margin-left: 2rem; }

p.no-margin {
  margin-left: 0; }

.card .card-content p {
  margin-bottom: 1rem; }

main ul {
  margin-left: 4rem; }

main ul:not(.browser-default) li {
  list-style-type: circle; }

main ul.collapsible li {
  list-style-type: none; }

.collapsible-body {
  background: #fff; }

.modal.modal-fixed-footer {
  height: 80%;
  max-height: 80%; }

.flag-icon {
  margin-right: 3px; }

.BlocPage select {
  display: block; }
