nav {
  margin-bottom: 20px;
}

.brand-logo {
  margin-left: 1rem;
  font-family: 'Satisfy', cursive;
}

.lang-dropdown {
  i {
    margin-left: 0;
    margin-right: 0;
  }
}